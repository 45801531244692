import * as React from "react";

import {Layout, MainLogo} from "../components/layout";
import Seo from "../components/seo";
import Video from "../components/video";

const streamers = [
  {
    goesBy: "Doe",
    username: "adoeadeer",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "Laid back streams focused on indie games, with the occasional art or design stream. Trying to build a friendly and inclusive community focused on friendships and connection."
  },
  {
    goesBy: "Ally",
    username: "AllyRaeven",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "The Raeven’s Nest community is a place to call a second home. It is a safe space which provides cozy, chill vibes and fosters friendships and personal growth. We strive for positivity while accepting that the world is often full of darkness. Together, we can spread our wings and rise above it."
  },
  {
    goesBy: "Anne",
    username: "AnneDorko",
    pronouns: "They/Them",
    labels: "Queer, Nonbinary, Trans",
    bio:
      "My mission is to combat anxiety with chaotic good! My personal avenue for that is to create life-affirming and self-reflective music. Most of the time I am songwriting and producing music live to eventually release on Spotify. This can be especially fun when chat gets involved in the creative process. When not doing music production, I feature live music performances, party streams with silly redeemable perks, and other variety content like graphic design, digital art, coding, and community games."
  },
  {
    goesBy: "Ashlyn",
    username: "Badeline_strawberry",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "I like to describe my channel as a warm and safe space for anyone to forget about daily life for a bit. We have fun, make a lot of dirty jokes, and will always validate you in how you are feeling. It is all about warmth and compassion, and I love my community dearly."
  },
  {
    goesBy: "Alicia",
    username: "bannarchist",
    pronouns: "She/Her",
    labels: "Bisexual, Queer",
    bio:
      "Silly, chatty, sometimes Very Gay streams with visits from my cat Raz, open and caring discussions, and a lot of- some would say constant- failures"
  },
  {
    goesBy: "Bea",
    username: "beawitched_",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "I aim to keep both streams and my community a safe, inclusive, accepting and positive place. Streams are 18+ since I do like to talk about mature topics but we also like to have fun and joke around."
  },
  {
    goesBy: "Bo",
    username: "Bochicchio",
    pronouns: "He/Him or They/Them",
    labels: "Genderfluid",
    bio:
      "I’m a variety streamers that likes to focus on rpg’s mmo and heavy story based games. While also being very community focused and interactive with my chat"
  },
  {
    goesBy: "Buff",
    username: "Buffpup",
    pronouns: "Any Pronouns",
    labels: "Anvisgender Pansexual",
    bio:
      "We are a funny looking dog making silly laughs and goofs! We are a silly, welcoming, comfy community and we welcome anyone and everyone!"
  },
  {
    goesBy: "Crow",
    username: "crowmunist",
    pronouns: "They/Them",
    labels: "Queer, Nonbinary",
    bio:
      "I’m a variety streamer with a love of first playthroughs, Nintendo, and bird puns!"
  },
  {
    goesBy: "DragnTears",
    username: "DragnTears",
    pronouns: "She/Her",
    labels: "Bisexual, Pansexual",
    bio:
      "They’re called the Sassholes for a very good reason. But we do it with love!"
  },
  {
    goesBy: "Fairy",
    username: "Fairiest",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "Both myself and my community could be described as wholesome but chaotic. We all aim for good vibes but also troll each other in non-harmful ways. We all also try to be very welcoming to anyone who makes their way to the stream. For the streams themselves, I’ve been doing a lot of horror which has been really fun; the community also enjoys it because I’m a big scaredy-cat and usually react to getting scared with colorful expletives!"
  },
  {
    goesBy: "Alice",
    username: "FoxQueen",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "I typically play story based games though I tend to lean towards visual novels and JRPGS, I’ve been told it’s typically anime games. I like to keep a chill positive community and I feel like all the people in my community tend to be very friendly, kind, and open to meeting new friends. The majority of everyone tends to be LGBTQIA+ as well."
  },
  {
    goesBy: "fuchsia",
    username: "fuchsiarascal",
    pronouns: "She/Herc",
    labels: "Queer, Bisexual, Demisexual, Ace Spectrum",
    bio:
      "cozy and welcoming space focused on creativity and mental health in the animal crossing community"
  },
  {
    goesBy: "Bri",
    username: "honeybri_",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "my streams are a variety of things with a focus on flow arts and rhythm games with a goal of inclusivity and spreading good vibes"
  },
  {
    goesBy: "Kisos",
    username: "itsKisos",
    pronouns: "He/Him",
    labels: "Queer",
    bio:
      "Kisos & community scream & skwirt their love to the world through weekly events like QUEERANTINE, an LGBTQ+ music video showcase, Mosaik, a weekly music production challenge, and play every game under the sun together (EXCEPT Sea of Thieves. Never again.)"
  },
  {
    goesBy: "Jeff",
    username: "JeffBrutlag",
    pronouns: "He/Him",
    labels: "Gay",
    bio:
      "We typically do lots of blind playthroughs of story driven games, horror games, Nintendo games, sometimes some simulation games (mostly Animal Crossing), but more than anything, we’re a community-based group of cozy, kind individuals! We have talks about queer topics and mental health, usually trying to balance the heaviness those topics can have with some positivity. While we mostly do games on the channel, we haven’t shied away from doing fun things like red carpet fashion reviews, Just Dance, and reading spooky stories!"
  },
  {
    goesBy: "Justin",
    username: "justin_nick",
    pronouns: "He/Him/They",
    labels: "Gay",
    bio:
      "Questionable gameplay meets a very chaotic laugh! Justin is a gay variety streamer with a community that is welcoming and safe for the entire LGBTQIA+ community."
  },
  {
    goesBy: "Jenny",
    username: "justjenny_",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "My streams are largely chat-interaction based, with a big focus on curating an accepting, loving, laugh-your butt-off community. In any one stream we can jump from expletive-laden jokes to serious mental-health real-talk and back again one-hundred times, but our primary focus is that, regardless of how silly we come off, everyone feels welcome and, most importantly, safe."
  },
  {
    goesBy: "Lan",
    username: "MagicalVelcro",
    pronouns: "She/Her",
    labels: "Demisexual, Bisexual",
    bio:
      "Variety is the spice of life~ My favourite types of games are ones with amazing stories and characters that make me feel all of the feels, and the option to explore to my heart’s content (and find all of the secrets). My community are a bunch of sweethearts who are supportive and silly, and who love to quote and clip me saying weird stuff. We talk about a variety of topics including mental health, sexuality, and poop."
  },
  {
    goesBy: "Melody",
    username: "melodyserafina",
    pronouns: "She/Her",
    labels: "Queer",
    bio:
      "i like to prioritize talking with chat while playing cute, chill, wholesome games. little bit of humour, little bit of chaos, and a lot of fun, relaxing vibes <3"
  },
  {
    goesBy: "Podgie",
    username: "podgie",
    pronouns: "She/Her",
    labels: "Queer, Bisexual, Pansexual",
    bio:
      "Our community is very positive and inclusive! We have kind of a space/alien theme and call ourselves the gayliens and con-sensually probe 🙂 I like to have a fun and safe environment where we can be our true selves and feel accepted by everyone."
  },
  {
    goesBy: "Rinn",
    username: "RinnSohma",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "I’ve always said that my streams focus on ‘silliness, not skill’ – I like to find opportunities to goof around on stream! My community is warm, welcoming and inclusive, and we often have multiplayer game evenings and movie nights. My stream goal is to continue building a safe space for anyone who needs it, whether members are chatters, lurkers or somewhere in between!"
  },
  {
    goesBy: "Shia",
    username: "ShiaBun",
    pronouns: "He/Him",
    labels: "Bisexual, Pansexual",
    bio:
      "We pride ourselves on our inclusivity, and I am so proud of our community who are ALL SO DANG NICE. We play a bunch of horror games, are almost never serious, and I may tell an awkward story or two about my large nose."
  },
  {
    goesBy: "Skittzi",
    username: "skittzipoo",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "I’m a variety streamer and cosplayer who loves playing indie games, RPGs, and more. My focus is on fostering a welcoming and positive community while having fun!"
  },
  {
    goesBy: "Sprinkles",
    username: "Sprinkl3saurus",
    pronouns: "She/Her",
    labels: "Pansexual",
    bio:
      "My streams are a potpourri of everything: games, positivity, burps, kittens, sarcasm, snacks. I play whatever I’m feeling at the moment or, sometimes, games suggested by the community. We love to support one another, be it through jokes, cute pet pictures, pep talks, or just someone to vent to. I always make it very clear that this community, this channel, all of it is an “us” thing; I just play games and bring people together."
  },
  {
    goesBy: "tapgirl",
    username: "tapgirl301",
    pronouns: "She/Her",
    labels: "Bisexual",
    bio:
      "The Penguin Colony is a chill chat focused community where we love to hang out and get to know each other. Each stream starts with some time in Just Chatting before hopping into a game. I play a wide variety of games and I have a lot that I want to share with the community so most streams we play two games. We usually start with some chill Animal Crossing for the first half. Sometimes I pick the next game sometimes I post a poll of my community to vote on what’s next. Games I have streamed recently include ESO, DBD, Lego Star Wars, and House Flipper."
  },
  {
    goesBy: "Ginger",
    username: "ThatGayGinger",
    pronouns: "They/Them",
    labels: "Socially Anxious Nonbinary Caterpillar",
    bio:
      "Variety streams with heavy story and queer emphasis. Sometimes we do cybersecurity education streams too!"
  },
  {
    goesBy: "Rain",
    username: "TheRainbowBard",
    pronouns: "They/Them",
    labels: "Nonbinary, Bisexual, Pansexual",
    bio:
      "TheRainbowBard is a variety streamer who strives to make an inclusive environment while still having a ton of fun. They enjoy all types of games, including ttrpgs and video games."
  },
  {
    goesBy: "Morgan",
    username: "tolstoyce",
    pronouns: "She/Her",
    labels: "Panromantic Demisexual",
    bio:
      "My community is a chill, friendly, brightly colored place for people to hang out, and is largely made up of queer gamers. It is also a safe space for serious discussion, particularly surrounding mental health and global issues that may be affecting said mental health."
  }
];

const Pride2020Page = () => {
  return (
    <Layout>
      <Seo title="LGBTQ+ Streamer Showcase for Pride 2020 | Plant Army" />
      <div className="pt-20 pb-8 lg:pt-32 lg:pb-24 container max-w-3xl	mx-auto">
        <div className="flex flex-wrap lg:flex-nowrap justify-center justify-items-center content-center items-center">
          <div className="lg:flex-none">
            <MainLogo />
          </div>
          <div className="text-center lg:text-left lg:flex-1 lg:pl-12 p-6 max-w-lg">
            <h2 className="font-serif text-3xl lg:text-4xl font-semibold leading-none lg:leading-none pb-4">
              LGBTQ+ Streamer Showcase for Pride 2020
            </h2>
            <p className="text-sm lg:text-base">
              Streamer gallery with links available below the video!
            </p>
          </div>
        </div>
      </div>
      <div className="container max-w-[1080px] mx-auto mb-6">
        <Video
          videoSrcURL="https://www.youtube-nocookie.com/embed/9nNeeADllEo"
          videoTitle="Plant Army LGBT+ Streamer Showcase"
        />
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap px-4 lg:px-0 justify-between">
          {streamers.map(item => {
            const link = "https://twitch.tv/" + item.username;
            return (
              <a
                key={item.username}
                href={link}
                target="_blank"
                rel="noreferrer"
                className="block w-full sm:w-[49%] lg:w-[23%] bg-white rounded drop-shadow-md mb-4 hover:drop-shadow-lg"
              >
                <div className="break-words">
                  <h3 className="break-words rounded-t bg-emerald-600 px-4 py-2 text-white">
                    {item.goesBy}{" "}
                    <span className="text-xs">({item.pronouns})</span>
                  </h3>
                  <p className="break-words bg-emerald-500 px-4 py-1 text-white text-sans text-xs">
                    {item.labels}
                  </p>
                  <p className="break-words text-xs p-4">{item.bio}</p>
                  <p className="break-words text-xs px-4 mb-4 text-emerald-700">
                    Follow on Twitch!
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Pride2020Page;
