import React from "react";
const Video = ({videoSrcURL, videoTitle, ...props}) => (
  <div className="video relative" style={{"padding-top": "56.25%"}}>
    <iframe
      className="absolute inset-0 w-full h-full"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      width="1020"
      height="608"
      allowFullScreen
    />
  </div>
);
export default Video;
